<template>
  <div>
    <div id="step0" class="px-3 pt-12 mt-6" v-if="countPersonalData === 0">
      <p key="InfoAditional" ref="InfoAditional" class="text-center black--text font-weight-bold subtitle-1">
        1. Datos esenciales de contacto
      </p>
      <v-img src="../../../assets/profesionales.png" aspect-ratio="1.7" contain style="border-radius: 50%"></v-img>
      <p class="grey--text text--darken-1 subtitle-1 text-center pt-4">
        Debes asegurarte que tus datos estén escritos correctamente. A tu
        <span class="subtitle-1 grey--text text--darken-1
                font-weight-bold text-center pt-4">
          correo y número de celular</span>
        se enviará información importante para que continues con tu proceso de selección.
      </p>
    </div>
    <div id="step1" class="px-3 pt-12 mt-6 d-flex justify-center" v-if="countPersonalData === 1">
      <v-form ref="form" v-model="addPersonalData.datosValidPer">
        <p key="DPname" ref="DPname" class="text-center black--text
                font-weight-bold subtitle mb-4">
          ¿Cuál es tu nombre?
        </p>
        <v-text-field :rules="nameRules" outlined pattern="[a-zA-Z]*" type="text" v-model.trim="addPersonalData.nombres"
          class="pt-0 grey-label" color="teal" maxlength="40" label="Nombres"></v-text-field>
        <p key="DPsurname" ref="DPsurname" class="text-center font-weight-bold black--text subtitle mb-4 mt-4">
          ¿Cuáles son tus apellidos?
        </p>
        <v-text-field :rules="lastName1Rules" class="grey-label" pattern="[a-zA-Z]*" outlined type="text"
          v-model.trim="addPersonalData.apellido_p" color="teal" label="Apellido Paterno" maxlength="30"></v-text-field>
        <v-text-field :rules="lastName2Rules" class="grey-label" outlined pattern="[a-zA-Z]*" type="text"
          v-model.trim="addPersonalData.apellido_m" color="teal" label="Apellido Materno" maxlength="30"></v-text-field>
        <v-row class="mt-3">
          <v-alert dense outlined type="error">
            Verifica que tus nombres y apellidos estén escritos como en tu <strong>documento.</strong>
          </v-alert>
        </v-row>
        <p key="DPborn" ref="DPborn" class="text-center black--text font-weight-bold
                subtitle my-4">
          ¿Cuál es tu fecha de nacimiento?
        </p>
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field class="grey-label" v-model="addPersonalData.fecha_nac" label="Fecha de nacimiento"
              color="teal" required outlined append-icon="mdi-calendar" readonly v-on="on"
              :rules="[v => !!v || 'Fecha de nacimiento es requerido']"></v-text-field>
          </template>
          <v-date-picker color="teal" locale="es" ref="picker" v-model="addPersonalData.fecha_nac" :max="isSixteenYearsOld()"
            min="1950-01-01" @change="saveBirthDay"></v-date-picker>
        </v-menu>
      </v-form>
    </div>
    <div id="step2" class="px-3 pt-12 mt-6" v-if="countPersonalData === 2">
      <v-form ref="form" v-model="addPersonalData.datosValidPer">
        <p key="DPgener" ref="DPgener" class="text-center black--text font-weight-bold
                subtitle mb-0">
          ¿Cuál es tu sexo?
        </p>
        <v-radio-group @change="changeGender" v-model="addPersonalData.genero" class="body-1" :mandatory="false"
          :rules="[v => !!v || 'Género es requerido']">
          <v-radio label="Femenino" value="Femenino" color="teal" class="pa-2 radioStateCivil"></v-radio>
          <v-radio label="Masculino" value="Masculino" color="teal" class="pa-2 radioStateCivil"></v-radio>
          <v-radio label="Intersexual" value="Intersexual" color="teal" class="pa-2 radioStateCivil"></v-radio>
        </v-radio-group>
        <v-expand-transition>
          <div id="step5.1" v-if="addPersonalData.genero === 'Intersexual'" class="px-3 pt-2">
            <p class="text-center black--text font-weight-bold subtitle mb-4">
              ¿Cuál es tu nombre social?
            </p>
            <v-text-field outlined label="Nombre social" v-model.trim="addPersonalData.nombre_social_intersexual"
              class="pt-2 grey-label" color="teal" maxlength="20"></v-text-field>
            <p class="grey--text body-2 text-justify pt-6">
              Nombre social*: "Nombre adoptado por una persona trans con la finalidad de coincidir mejor con su
              identidad de género, con la que se siente mejor identificada y reconocida".
            </p>
          </div>
        </v-expand-transition>
      </v-form>
    </div>
    <div id="step3" class="px-3 pt-12 mt-6" v-if="countPersonalData === 3">
      <v-form ref="form" v-model="addPersonalData.datosValidPer">
        <p key="city" ref="city" class="text-center black--text font-weight-bold
                subtitle mb-0">
          ¿En cuál provincia vives?
        </p>
        <v-radio-group v-model="addPersonalData.provincia" @change="changeDistrict" class="body-1 mt-4"
          :mandatory="false" :rules="[v => !!v || 'Tu provincia de residencia es requerida']">
          <v-radio label="Chiclayo" value="E" color="teal" class="pa-2 radioStateCivil"></v-radio>
          <v-radio label="Lima" value="A" color="teal" class="pa-2 radioStateCivil"></v-radio>
          <v-radio label="Piura" value="P" color="teal" class="pa-2 radioStateCivil"></v-radio>
          <v-radio label="Trujillo" value="T" color="teal" class="pa-2 radioStateCivil"></v-radio>
          <v-radio label="Arequipa" value="AREQUIPA" color="teal" class="pa-2 radioStateCivil"></v-radio>
          <v-radio label="Otra" value="OTHER" color="teal" class="pa-2 radioStateCivil"></v-radio>
        </v-radio-group>
        <v-expand-transition>
          <div id="step5.1" v-if="addPersonalData.provincia !== ''" class="px-3 pt-2">
            <p key="district" ref="district" class="text-center black--text font-weight-bold subtitle mb-4">
              ¿Cuál es tu distrito de residencia?
            </p>
            <v-select v-if="addPersonalData.provincia !== 'OTHER'" v-model="addPersonalData.distrito" class="grey-label"
              color="teal" :items="availableDistricts" :rules="[v => !!v || 'El distrito es requerido']"
              label="Distrito" outlined required>
            </v-select>
            <v-text-field v-else v-model="addPersonalData.distrito" class="grey-label" color="teal"
              :rules="[v => !!v || 'El distrito es requerido']" label="Distrito" outlined required>
            </v-text-field>
          </div>
        </v-expand-transition>
      </v-form>
    </div>
    <div id="step4" class="px-3 pt-12 mt-6" v-if="countPersonalData === 4">
      <v-form ref="form" v-model="addPersonalData.datosValidPer">
        <p key="DPphone" ref="DPphone" class="text-center black--text font-weight-bold
                subtitle mb-2 pt-4">
          ¿Cuál es tu número de celular?
        </p>
        <p class="caption grey--text text--darken-2">
          Asegúrate de registrar un número celular donde contactemos fácilmente contigo.
        </p>
        <v-text-field class="grey-label" min="0" outlined label="Número de celular"
          v-model.trim="addPersonalData.telefono" placeholder="555555555" color="teal" required maxlength="9"
          type="number" pattern="[0-9]*"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          @keypress="isNumber($event)" :rules="phoneRules" />
        <v-text-field class="grey-label" min="0" outlined label="Celular Adicional"
          v-model.trim="addPersonalData.telefonoReferencial" placeholder="999999999" color="teal" required maxlength="9"
          type="number" pattern="[0-9]*"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          @keypress="isNumber($event)" :rules="additionalPhoneRules"></v-text-field>
        <p key="DPemail" ref="DPemail" class="text-center black--text font-weight-bold
              subtitle mb-2">
          ¿Cuál es tu correo electrónico?
        </p>
        <p class="caption grey--text text--darken-2">
          Asegúrate de registrar el correo que usas habitualmente.
        </p>
        <v-text-field outlined class="grey-label" label="Correo electrónico" v-model.trim="addPersonalData.correo"
          :rules="emailRules" placeholder="example@gmail.com" color="teal" required oncopy="return false"
          onpaste="return false"></v-text-field>
        <v-text-field v-model.trim="addPersonalData.correoConfirmacion" outlined class="grey-label"
          label="Confirmación del correo" :rules="[emailConfirmationRules, emailConfirmationRules2]"
          placeholder="Confirmación del correo" color="teal" required oncopy="return false"
          onpaste="return false"></v-text-field>
      </v-form>
    </div>
    <div id="step5" class="px-3 pt-12 mt-6" v-if="countPersonalData === 5">
      <v-form ref="form" v-model="addPersonalData.datosValidPer">
        <p key="DPcomoKonecta" ref="DPcomoKonecta" class="text-center black--text font-weight-bold subtitle mb-4">
          ¿Cómo conociste a Konecta?
        </p>
        <v-select @change="changeReference" outlined v-model="addPersonalData.como_konecta" :items="itemsComoKonecta"
          :rules="[v => !!v || 'Selecciona una opción']" label="Nos conociste" color="teal"
          class="grey-label"></v-select>
        <v-expand-transition>
          <div id="step8.1" v-if="addPersonalData.como_konecta === 'Referido/a'" class="pt-4">
            <p key="DPtypedoc" ref="DPtypedoc" class="text-center black--text font-weight-bold subtitle mb-2 mt-1">
              Tipo de documento
            </p>
            <p key="DPcolaborator" ref="DPcolaborator" class="text-center grey--text text--darken-3 body-1 mb-4">
              Ingresa los datos del colaborador que trabaja en Konecta
            </p>
            <v-select v-model="addPersonalData.documentTypeColaborador" :items="DocumentType"
              :rules="[v => !!v || 'El tipo de documento es requerido']" label="Seleccionar" class="grey-label" outlined
              required></v-select>
            <v-text-field v-if="
              addPersonalData.documentTypeColaborador !== 'Pasaporte' &&
              addPersonalData.documentTypeColaborador !== 'Permiso Temporal de Permanencia' &&
              addPersonalData.documentTypeColaborador !== 'Carnet de Extranjeria'
            " v-model.trim="addPersonalData.nroDoc_colaborador" type="number" class="grey-label" maxlength="8" outlined
              pattern="[0-9]*" oninput="javascript:
                    if (this.value.length > this.maxLength)
                    this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)" :rules="dniRules"
              placeholder="Número de DNI" label="Número" required></v-text-field>
            <v-text-field v-if="addPersonalData.documentTypeColaborador === 'Pasaporte'"
              v-model.trim="addPersonalData.nroDoc_colaborador" class="grey-label" maxlength="11" outlined
              label="Número de Pasaporte" type="number" pattern="[0-9]*" oninput="javascript:
                    if (this.value.length > this.maxLength)
                    this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)"
              :rules="pasaporteRules" placeholder="Ingrese el número de pasaporte" required></v-text-field>

            <v-text-field v-if="
              addPersonalData.documentTypeColaborador === 'Permiso Temporal de Permanencia' ||
              addPersonalData.documentTypeColaborador === 'Carnet de Extranjeria'
            " v-model.trim="addPersonalData.nroDoc_colaborador" maxlength="9" outlined label="Número de documento"
              class="grey-label" type="number" pattern="[0-9]*" oninput="javascript:
                    if (this.value.length > this.maxLength)
                    this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)" :rules="ptpteRules"
              placeholder="Ingrese el número de documento" required></v-text-field>
            <v-checkbox v-model="addPersonalData.misma_cuenta" label="Quiero trabajar en la misma cuenta" value="Si"
              color="teal" hide-details required class="pt-6 body-2 black--text"></v-checkbox>
          </div>
        </v-expand-transition>
      </v-form>
    </div>
    <div id="step5" class="px-3 pt-12 mt-6" v-if="countPersonalData === 6">
      <p key="InfoAditional" ref="InfoAditional" class="text-center black--text font-weight-bold subtitle-1">
        2. Cuéntanos sobre tu formación académica
      </p>
      <v-img src="../../../assets/profesionales.png" aspect-ratio="1.7" contain style="border-radius: 50%"></v-img>
      <p class="grey--text text--darken-1 subtitle-1 text-center pt-4">
        Tu información académica nos ayudará a poder conocerte mejor y ofrecerte propuestas especiales para ti.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'personal_ata_step',
  props: {
    countPersonalData: {
      default: 0,
    },
    addPersonalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nameRules: [
        (v) => !!v || 'El nombre es requerido',
        (v) => /^[áéíóúÁÉÍÓÚñÑäöüÄÖÜ a-zA-Z]+$/.test(v) || 'El nombre no es válido, sólo se aceptan letras, tilde y espacio',
      ],
      availableDistricts: [],
      dniRules: [
        (v) => !!v || 'Ingresa el DNI del colaborador que trabaja en Konecta',
        (v) => (v && v.length === 8) || 'El número debe ser de 8 carácteres',
      ],
      phoneRules: [
        (v) => !!v || 'Ingresa el número de celular',
        (v) => (v && v.length === 9) || 'El número debe ser de 9 dígitos',
        (v) => /^[0-9]{0,9}$/.test(v) || 'El número debe ser de 9 dígitos',
      ],
      additionalPhoneRules: [
        (v) => !!v || 'Ingresa el número de celular adicional',
        (v) => (v && v.length === 9) || 'El número debe ser de 9 dígitos',
        (v) => /^[0-9]{0,9}$/.test(v) || 'El número debe ser de 9 dígitos',
      ],
      pasaporteRules: [
        (v) => !!v || 'El número de documento es requerido',
        (v) => (v && v.length === 11) || 'El número debe ser de 11 carácteres',
      ],
      ptpteRules: [
        (v) => !!v || 'El número de documento es requerido',
        (v) => (v && v.length === 9) || 'El número debe ser de 9 carácteres',
      ],
      DocumentType: ['DNI', 'Pasaporte', 'Carnet de Extranjeria', 'Permiso Temporal de Permanencia'],
      menu: false,
      itemsComoKonecta: [
        'Bumeran',
        'Computrabajo',
        'Columbia',
        'Facebook',
        'Instagram',
        'TikTok',
        'Programa Basta Ya',
        'Programa Inkluye',
        'Programa SUD',
        'Referido/a',
        'Feria Laboral',
        'Bolsa Laboral Instituto',
        'Bolsa Laboral Universidad',
        'Ministerio de Trabajo'
      ],
      lastName1Rules: [
        (v) => !!v || 'Apellido paterno es requerido',
        (v) => /^[áéíóúÁÉÍÓÚñÑäöüÄÖÜ a-zA-Z]+$/.test(v)
          || 'El apellido  no es válido , sólo se aceptan letras, tílde y espacio',
      ],
      lastName2Rules: [
        (v) => !!v || 'Apellido materno es requerido',
        (v) => /^[áéíóúÁÉÍÓÚñÑäöüÄÖÜ a-zA-Z]+$/.test(v)
          || 'El apellido no es válido, sólo se aceptan letras, tílde y espacio',
      ],
      emailRules: [(v) => !!v || 'El correo es requerido', (v) => /.+@.+\..+/.test(v) || 'El correo no es válido'],
      emailConfirmationRules: [
        (v) => !!v || 'Es requerido la confirmación del correo',
        (v) => v === this.correo || 'No se encontraron coincidencias',
      ],
      availableArequipaDistricts: [
        'Arequipa',
        'Alto Selva Alegre',
        'Cayma',
        'Cerro Colorado',
        'Characato',
        'Chiguata',
        'Jacobo Hunter',
        'Jose Luis Bustamante y Rivero',
        'La Joya',
        'Mariano Melgar',
        'Miraflores',
        'Mollebaya',
        'Paucarpata',
        'Pocsi',
        'Polobaya',
        'Quequeña',
        'Sabandia',
        'Sachaca',
        'San Juan de Siguas',
        'San Juan de Tarucani',
        'Santa Isabel de Siguas',
        'Socabaya',
        'Tiabaya',
        'Uchumayo',
        'Vitor',
        'Yanahuara',
        'Yarabamba',
        'Yura',
      ],
      availableTrujilloDistricts: [
        'El Porvenir',
        'Florencia de Mora',
        'Huanchaco',
        'La Esperanza',
        'Laredo',
        'Moche',
        'Poroto',
        'Salaverry',
        'Simbal',
        'Trujillo',
        'Víctor Larco Herrera',
      ],
      availableChiclayoDistricts: [
        'Cayaltí',
        'Chiclayo',
        'Chongoyape',
        'Eten',
        'Puerto Eten',
        'José Leonardo Ortiz',
        'La Victoria',
        'Lagunas',
        'Monsefú',
        'Nueva Arica',
        'Oyotún',
        'Pátapo',
        'Picsi',
        'Pimentel',
        'Pomalca',
        'Pucalá',
        'Reque',
        'Santa Rosa',
        'Tumán',
        'Zaña',
      ],
      availablePiuraDistricts: [
        'Piura',
        'Castilla',
        'Catacaos',
        'La Arena',
        'La Unión',
        'Las Lomas',
        'Tambo Grande',
        'Cura Mori',
        'El Tallán',
        'Veintiséis de Octubre',
      ],
      availableLimaDistricts: [
        'Ancón',
        'Ate',
        'Barranco',
        'Breña',
        'Carabayllo',
        'Chaclacayo',
        'Chorrillos',
        'Cieneguilla',
        'Comas',
        'El agustino',
        'Independencia',
        'Jesús María',
        'La Molina',
        'La Victoria',
        'Lima',
        'Lince',
        'Los Olivos',
        'Lurigancho',
        'Lurín',
        'Magdalena del mar',
        'Miraflores',
        'Pachacámac',
        'Pucusana',
        'Pueblo Libre',
        'Puente Piedra',
        'Punta Hermosa',
        'Punta Negra',
        'Rímac',
        'San Bartolo',
        'San Borja',
        'San Isidro',
        'San Juan de Lurigancho',
        'San Juan de Miraflores',
        'San Luis',
        'San Martin de Porres',
        'San Miguel',
        'Santa Anita',
        'Santa María del Mar',
        'Santa Rosa',
        'Santiago de Surco',
        'Surquillo',
        'Villa El Salvador',
        'Villa María del Triunfo',
      ],
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.addPersonalData.fecha_nac);
    },
    emailConfirmationRules2() {
      return () => this.addPersonalData.correo === this.addPersonalData.correoConfirmacion || 'No se encontraron coincidencias';
    },
  },
  watch: {
    menu(val) {
      // eslint-disable-next-line no-unused-expressions
      // eslint-disable-next-line no-return-assign
      return val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  methods: {
    isSixteenYearsOld() {
      const today = new Date();
      const sixteenYearsAgo = new Date(
        today.getFullYear() - 16,
        today.getMonth(),
        today.getDate()
      );

      const formattedDate = sixteenYearsAgo.toISOString().split("T")[0];

      return formattedDate;
    },
    isAdult() {
      const date1 = new Date();
      date1.setMonth(date1.getMonth() - 192); // 16 years
      const finalDate = `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}`;
      return finalDate;
    },
    changeDistrict(district) {
      let districtSelected;
      this.addPersonalData.distrito = '';

      switch (district) {
        case 'A':
          districtSelected = 'Lima';
          this.availableDistricts = this.availableLimaDistricts;
          break;
        case 'Q':
          districtSelected = 'Arequipa';
          this.availableDistricts = this.availableArequipaDistricts;
          break;
        case 'E':
          districtSelected = 'Chiclayo';
          this.availableDistricts = this.availableChiclayoDistricts;
          break;
        case 'P':
          districtSelected = 'Piura';
          this.availableDistricts = this.availablePiuraDistricts;
          break;
        case 'T':
          districtSelected = 'Trujillo';
          this.availableDistricts = this.availableTrujilloDistricts;
          break;
        case 'AREQUIPA':
          districtSelected = 'Arequipa';
          this.availableDistricts = this.availableArequipaDistricts;
          break;
        case 'OTHER':
          break;

      }

      this.$gtag.event(`LP_Residencia${districtSelected}`, {
        event_category: `LP_Residencia${districtSelected}`,
        event_label: `LP_Residencia${districtSelected}`,
        value: 1,
      });
    },
    isNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    saveBirthDay(fechaNac) {
      this.$refs.menu.save(fechaNac);
    },
    changeGender(gender) {
      this.$gtag.event(`LP_Sexo_${gender}`, {
        event_category: `LP_Sexo_${gender}`,
        event_label: `LP_Sexo_${gender}`,
        value: 1,
      });
    },
    changeReference(reference) {
      this.$gtag.event(`LP_select_${reference}`, {
        event_category: `LP_select_${reference}`,
        event_label: `LP_select_${reference}`,
        value: 1,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-application .title {
  line-height: 23px !important;
}

.input-google-container {
  width: 100%;
  margin-top: 0.5em;
}

.input-autocomplete {
  margin-bottom: 2em;
  border-bottom: 2px solid #bdbdbd;
  transition: border-bottom 0.5s ease, width 0.5s ease;
  width: 100%;
}

.input-autocomplete:focus {
  width: 100%;
  border-bottom: 2px solid #168d86;
  outline: none;
}

.text-marker-content {
  display: flex;
  margin-bottom: 1em;
}

.marker-image {
  width: 20px;
  height: 30px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
</style>
